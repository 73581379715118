var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _c(
      "div",
      { staticClass: "w-10/12 mx-auto mt-6 mb-10" },
      [
        _c("h1", { staticClass: "text-center mb-8 text-2xl font-normal" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.contact_us"))),
        ]),
        _vm.isLoggedIn && _vm.loginUserRole === "provider"
          ? [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "お問合せ前に以下の項目から解決できるかお試しください。"
                ),
              ]),
              _c("div", { staticClass: "w-full grid grid-cols-3 gap-6" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "col-span-1 border rounded-lg p-2 cursor-pointer relative overflow-hidden h-20",
                    attrs: {
                      href: "/faq/faq-product-regist/",
                      target: "_blank",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "absolute inset-0 object-cover",
                      attrs: {
                        src: require("@/assets/image/contact_register.jpg"),
                      },
                    }),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-white absolute bottom-2 left-2 text-shadow",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("components.pulldownModal.help.guide")
                            ) +
                            " "
                        ),
                        _c(
                          "svg",
                          {
                            staticClass: "w-5 h-5 pb-1 inline",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              "stroke-width": "1.5",
                              stroke: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass:
                        "absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-20 active:bg-black",
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "col-span-1 border rounded-lg p-2 cursor-pointer relative overflow-hidden",
                    attrs: { href: "/help/ch", target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticClass: "absolute inset-0 object-cover",
                      attrs: {
                        src: require("@/assets/image/contact_control.jpg"),
                      },
                    }),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-white absolute bottom-2 left-2 text-shadow",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("components.pulldownModal.help.manual")
                            ) +
                            " "
                        ),
                        _c(
                          "svg",
                          {
                            staticClass: "w-5 h-5 pb-1 inline",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              "stroke-width": "1.5",
                              stroke: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass:
                        "absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-20 active:bg-black",
                    }),
                  ]
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "col-span-1 border rounded-lg p-2 cursor-pointer relative overflow-hidden",
                    attrs: { href: "/faq", target: "_blank" },
                  },
                  [
                    _c("img", {
                      staticClass: "absolute inset-0 object-cover",
                      attrs: { src: require("@/assets/image/contact_faq.jpg") },
                    }),
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-white absolute bottom-2 left-2 text-shadow",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("components.pulldownModal.help.faq")
                            ) +
                            " "
                        ),
                        _c(
                          "svg",
                          {
                            staticClass: "w-5 h-5 pb-1 inline",
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24",
                              "stroke-width": "1.5",
                              stroke: "currentColor",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round",
                                d: "M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("div", {
                      staticClass:
                        "absolute inset-0 bg-white bg-opacity-0 hover:bg-opacity-20 active:bg-black",
                    }),
                  ]
                ),
                _c("div", { staticClass: "col-span-1" }),
              ]),
            ]
          : _vm._e(),
        _c("div", { staticClass: "w-full mb-8" }, [
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("pages.users.contact.explanation")) +
                " " +
                _vm._s(_vm.$t("pages.users.contact.explanation2")) +
                " " +
                _vm._s(_vm.$t("pages.users.contact.explanation3")) +
                " " +
                _vm._s(_vm.$t("pages.users.contact.explanation4")) +
                " "
            ),
          ]),
        ]),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.isLoggedIn && _vm.loginUserRole === "provider") ||
                (!_vm.isLoggedIn && _vm.$i18n.locale === "ja") ||
                (_vm.isLoggedIn &&
                  _vm.loginUserRole === "manager" &&
                  _vm.$i18n.locale === "ja"),
              expression:
                "\n        (isLoggedIn && loginUserRole === 'provider') ||\n        (!isLoggedIn && $i18n.locale === 'ja') ||\n        (isLoggedIn && loginUserRole === 'manager' && $i18n.locale === 'ja')\n      ",
            },
          ],
          attrs: { id: "hubspotFormJp" },
        }),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.isLoggedIn && _vm.loginUserRole === "consumer") ||
                (!_vm.isLoggedIn && _vm.$i18n.locale !== "ja") ||
                (_vm.isLoggedIn &&
                  _vm.loginUserRole === "manager" &&
                  _vm.$i18n.locale !== "ja"),
              expression:
                "\n        (isLoggedIn && loginUserRole === 'consumer') ||\n        (!isLoggedIn && $i18n.locale !== 'ja') ||\n        (isLoggedIn && loginUserRole === 'manager' && $i18n.locale !== 'ja')\n      ",
            },
          ],
          attrs: { id: "hubspotFormEn" },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }